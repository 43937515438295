"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getApertureHistoryLink = getApertureHistoryLink;
exports.getApertureProfileLink = getApertureProfileLink;

var _queryStringForAll = _interopRequireDefault(require("query-string-for-all"));

var _window$APERTURE_URL;

const baseUrl = (_window$APERTURE_URL = window.APERTURE_URL) !== null && _window$APERTURE_URL !== void 0 ? _window$APERTURE_URL : 'http://localhost:8000';

function getApertureHistoryLink({
  organisationId,
  siteId,
  equipId,
  ruleId,
  occurrenceId,
  range: [from, to],
  locale
}) {
  const query = _queryStringForAll.default.stringify({
    organisationId,
    siteId,
    equipId,
    ruleId,
    occurrenceId,
    from: from.toISOString(),
    to: to.toISOString()
  });

  return `${baseUrl}/${locale}/inbound/bonfire/insight?${query}`;
}

function getApertureProfileLink(locale) {
  return `${baseUrl}/${locale}/settings/profile`;
}