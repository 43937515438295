"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.EDIT_MODAL_DATA = exports.ADD_MODAL_DATA = void 0;

var _actionCreators = require("./actionCreators");

var _actionTypes = require("./actionTypes");

// Other constants
const ADD_MODAL_DATA = {
  title: 'Add Group',
  submitText: 'Add Group',
  submitStyle: 'success'
};
exports.ADD_MODAL_DATA = ADD_MODAL_DATA;
const EDIT_MODAL_DATA = {
  title: 'Edit Group',
  submitText: 'Save Changes',
  submitStyle: 'success'
};
exports.EDIT_MODAL_DATA = EDIT_MODAL_DATA;
const INITIAL_GROUP_FORM_DATA = {};
const initialState = {
  groups: [],
  projects: {},
  users_loaded: false,
  adding: false,
  editing: false,
  working: false,
  group_form_data: INITIAL_GROUP_FORM_DATA,
  modal_data: {},
  success: null,
  project_added: false,
  project_edited: false
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case _actionTypes.LOAD_GROUPS:
      return { ...state
      };

    case _actionTypes.LOAD_GROUPS_SUCCESS:
      return { ...state,
        groups: action.result,
        error: null
      };

    case _actionTypes.ADDING_GROUP:
      return { ...state,
        adding: true,
        modal_data: ADD_MODAL_DATA,
        success: null
      };

    case _actionTypes.ADD_GROUP:
      return { ...state,
        working: true
      };

    case _actionTypes.ADD_GROUP_FAIL:
      return { ...state,
        adding: true,
        working: false,
        // TODO: This is a catchall, need to improve error handling.
        error: `${action.error.message || action.error.error} - The selected Group Name likely already exists in Bonfire`
      };

    case _actionTypes.ADD_GROUP_SUCCESS:
      {
        const groups = [...state.groups];
        const newGroup = {
          group_id: state.group_form_data.group_key,
          // NOTE: This id is temporary to avoid `unique key` warning
          group_type: state.group_form_data.group_type,
          group_name: state.group_form_data.group_name,
          group_key: state.group_form_data.group_key,
          project: state.group_form_data.project
        };
        groups.unshift(newGroup);
        const projectAdded = newGroup.group_type === 'project';
        return { ...state,
          error: null,
          adding: false,
          working: false,
          groups,
          group_form_data: INITIAL_GROUP_FORM_DATA,
          success: `Group **${state.group_form_data.group_name}** is added successfully.`,
          project_added: projectAdded
        };
      }

    case _actionTypes.SAVE_GROUP:
      return { ...state,
        working: true
      };

    case _actionTypes.SAVE_GROUP_FAIL:
      return { ...state,
        editing: false,
        working: false,
        error: action.error.message || action.error.error
      };

    case _actionTypes.SAVE_GROUP_SUCCESS:
      {
        const groups = [...state.groups];
        const groupIndex = groups.findIndex(group => group.group_id === state.group_form_data.group_id);
        groups.splice(groupIndex, 1, state.group_form_data);
        return { ...state,
          error: null,
          editing: false,
          working: false,
          groups,
          user_form_data: INITIAL_GROUP_FORM_DATA,
          success: `Changes to Group **${state.group_form_data.group_name}** is successfully saved.`
        };
      }

    case _actionTypes.EDITING_GROUP:
      return { ...state,
        editing: true,
        modal_data: EDIT_MODAL_DATA,
        group_form_data: action.row,
        submit_func: _actionCreators.saveGroupRequest,
        success: null
      };

    case _actionTypes.EDITING_GROUP_FORM:
      {
        const groupFormData = { ...state.group_form_data
        };
        groupFormData[action.input_id] = action.input_value;

        if (action.input_id === 'group_type' && action.input_value === 'project') {
          groupFormData.project = null;
        }

        if (action.input_id === 'group_type' && action.input_value === 'site') {
          groupFormData.group_key = null;
        }

        if (action.input_id === 'group_type' && action.input_value === 'group') {
          groupFormData.project = null;
          groupFormData.group_key = null;
        }

        return { ...state,
          group_form_data: groupFormData
        };
      }

    case _actionTypes.END_ACTION:
      return { ...state,
        editing: false,
        adding: false,
        group_form_data: INITIAL_GROUP_FORM_DATA,
        error: null
      };

    case _actionTypes.VALIDATE_FORM_ERROR:
      return { ...state,
        error: action.error.join('<br>')
      };

    default:
      return state;
  }
}